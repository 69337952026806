#controls-panel {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-radius: 1rem;
  z-index: 1;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
