html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

* {
  font-family: 'Comfortaa';
  font-weight: normal;
}
