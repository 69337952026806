html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  font-family: Comfortaa;
  font-weight: normal;
}

#controls-panel {
  z-index: 1;
  border-radius: 1rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

#controls-panel .container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.layer-controls {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.layer-button {
  min-width: 5rem;
  color: #edf7ed;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #7ad6f4;
  border-radius: .5rem;
  margin: .1rem;
  padding: .2rem;
  font-size: .8rem;
}

.layer-button--enabled {
  color: #032f64;
  background-color: #45bdee;
  border-color: #45bdee;
}

.layer-button:hover {
  background-color: #7ad6f4;
  border-color: #7ad6f4;
}

.layer-button:active {
  background-color: #28a7ea;
  border-color: #28a7ea;
}

/*# sourceMappingURL=index.6183ebbd.css.map */
