$text-color: #edf7ed;
$text-color--enabled: #032f64;
$bg-color: transparent;
$bg-color--hover: #7ad6f4;
$bg-color--active: #28a7ea;
$bg-color--enabled: #45bdee;
$border-color: #7ad6f4;
$border-color--active: #28a7ea;
$border-color--enabled: #45bdee;

.layer-button {
  min-width: 5rem;
  margin: 0.1rem;
  padding: 0.2rem;
  color: $text-color;
  background-color: $bg-color;
  border-radius: 0.5rem;
  border: 2px solid $border-color;
  font-size: 0.8rem;
  cursor: pointer;

  &--enabled {
    color: $text-color--enabled;
    border-color: $border-color--enabled;
    background-color: $bg-color--enabled;
  }
  
  &:hover {
    border-color: $border-color;
    background-color: $bg-color--hover;
  }

  &:active {
    border-color: $border-color--active;
    background-color: $bg-color--active;
  }
}
